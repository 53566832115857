import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import {
    HighscoreUser,
    Idea,
    IdeaCreateRquest,
    License,
    Place,
    PlaceLeaveable,
    PlaceStats,
    Post,
    PostRequest,
    Prize,
    PrizeRequest,
    Transaction,
    UserStream,
    StreamView,
    Event,
    Election,
} from "../definitions";
import { BaseService } from "./base.service";

@Injectable({
    providedIn: "root",
})
export class PlacesService {
    constructor(private baseService: BaseService) {}

    place(place: Partial<Place>): Observable<Place> {
        return this.baseService.get("places/" + place.id).pipe(map((response) => response.data));
    }
    delete(place: Partial<Place>): Observable<Place> {
        return this.baseService.delete("places/" + place.id).pipe(map((response) => response.data));
    }
    update(place: Partial<Place>, data: Partial<Place>): Observable<Place> {
        return this.baseService.put("places/" + place.id, data).pipe(map((response) => response.data));
    }
    /**
     * Für das austreten gibt es eine spezielles Params um die Infos für das verlassen zu bekommen
     *  Die Queries sind ziemlich teuer, darum als Flag
     */
    placesLeaveable(): Observable<PlaceLeaveable[]> {
        return this.baseService.get("places?show_leaveable=1").pipe(map((response) => response.data));
    }

    places(): Observable<Place[]> {
        return this.baseService.get("places").pipe(map((response) => response.data));
    }
    join(code: string): Observable<Place> {
        return this.baseService.post("places/join", { code }).pipe(map((response) => response.data));
    }
    createSponsoredPlace(code: string): Observable<Place> {
        return this.baseService.post("places/create", { code }).pipe(map((response) => response.data));
    }
    create(title: string): Observable<Place> {
        return this.baseService.post("places/create/free", { title }).pipe(map((response) => response.data));
    }
    check(code: string): Observable<any> {
        return this.baseService.get("check/" + code);
    }
    leave(place: Partial<Place>): Observable<any> {
        return this.baseService.post("places/" + place.id + "/leave", null).pipe(map((response) => response.data));
    }

    stream(place: Partial<Place>, page: number = 1, streamView: StreamView = StreamView.All): Observable<Post[]> {
        return this.baseService
            .get(`places/${place.id}/stream?page=${page}&streamView=${streamView}`)
            .pipe(map((response) => response.data));
    }
    licenses(place: Partial<Place>): Observable<License[]> {
        return this.baseService.get("places/" + place.id + "/licenses").pipe(map((response) => response.data));
    }
    admins(place: Partial<Place>): Observable<UserStream[]> {
        return this.baseService.get("places/" + place.id + "/admins").pipe(map((response) => response.data));
    }
    removeAdmin(place: Partial<Place>, user: UserStream): Observable<UserStream[]> {
        return this.baseService.delete("places/" + place.id + "/admins/" + user.id).pipe(map((response) => response.data));
    }
    addAdmin(place: Partial<Place>, username: string): Observable<UserStream> {
        return this.baseService.put("places/" + place.id + "/admins", { username }).pipe(map((response) => response.data));
    }

    stats(place: Partial<Place>): Observable<PlaceStats> {
        return this.baseService.get("places/" + place.id + "/stats").pipe(map((response) => response.data));
    }
    highscore(place: Partial<Place>): Observable<HighscoreUser[]> {
        return this.baseService.get("places/" + place.id + "/highscore").pipe(map((response) => response.data));
    }
    ideas(place: Partial<Place>, status: string) {
        return this.baseService.get("places/" + place.id + "/ideas?status=" + status).pipe(map((response) => response.data));
    }
    idea(place: Partial<Place>, idea: Partial<Idea>) {
        return this.baseService.get("places/" + place.id + "/ideas/" + idea.id).pipe(map((response) => response.data));
    }
    election(place: Partial<Place>, election: Partial<Election>) {
        return this.baseService.get("places/" + place.id + "/elections/" + election.id).pipe(map((response) => response.data));
    }
    readIdea(place: Partial<Place>, idea: Partial<Idea>) {
        return this.baseService.get("places/" + place.id + "/ideas/" + idea.id + "/read").pipe(map((response) => response.data));
    }
    unreadIdea(place: Partial<Place>, idea: Partial<Idea>) {
        return this.baseService.get("places/" + place.id + "/ideas/" + idea.id + "/unread").pipe(map((response) => response.data));
    }

    favoritIdea(place: Partial<Place>, idea: Partial<Idea>) {
        return this.baseService.get("places/" + place.id + "/ideas/" + idea.id + "/favorite").pipe(map((response) => response.data));
    }
    unfavoritIdea(place: Partial<Place>, idea: Partial<Idea>) {
        return this.baseService.get("places/" + place.id + "/ideas/" + idea.id + "/unset_favorite").pipe(map((response) => response.data));
    }

    deleteIdea(place: Partial<Place>, idea: Idea) {
        return this.baseService.delete("places/" + place.id + "/ideas/" + idea.id).pipe(map((response) => response.data));
    }
    createPost(place: Partial<Place>, request: PostRequest) {
        return this.baseService.post("places/" + place.id + "/posts", request).pipe(map((response) => response.data));
    }
    updatePostPost(place: Partial<Place>, post: Partial<Post>, request: PostRequest) {
        return this.baseService.put("places/" + place.id + "/posts/" + post.id, request).pipe(map((response) => response.data));
    }
    deletePost(place: Partial<Place>, post: Partial<Post>) {
        return this.baseService.delete("places/" + place.id + "/posts/" + post.id).pipe(map((response) => response.data));
    }
    createIdea(place: Partial<Place>, idea: IdeaCreateRquest) {
        return this.baseService.post("places/" + place.id + "/ideas", idea).pipe(map((response) => response.data));
    }

    prizes(place: Partial<Place>): Observable<Prize[]> {
        return this.baseService.get("places/" + place.id + "/prizes").pipe(map((response) => response.data));
    }
    prize(place: Partial<Place>, prize: Partial<Prize>): Observable<Prize> {
        return this.baseService.get("places/" + place.id + "/prizes/" + prize.id).pipe(map((response) => response.data));
    }
    createPrize(place: Partial<Place>, prize: PrizeRequest): Observable<Prize> {
        return this.baseService.post("places/" + place.id + "/prizes", prize).pipe(map((response) => response.data));
    }
    updatePrize(place: Partial<Place>, prize: Partial<Prize>, params: PrizeRequest): Observable<Prize> {
        return this.baseService.put("places/" + place.id + "/prizes/" + prize.id, params).pipe(map((response) => response.data));
    }
    deletePrize(place: Partial<Place>, prize: Prize) {
        return this.baseService.delete("places/" + place.id + "/prizes/" + prize.id).pipe(map((response) => response.data));
    }
    claimPrize(code: string): Observable<Transaction> {
        return this.baseService.post("prizes/claim", { code }).pipe(map((response) => response.data));
    }
    printPrize(place: Partial<Place>, prize: Partial<Prize>) {
        return this.baseService.get("places/" + place.id + "/prizes/" + prize.id + "/print").pipe(map((response) => response.data));
    }
    printEvent(place: Partial<Place>, event: Partial<Event>) {
        return this.baseService.get("places/" + place.id + "/events/" + event.id + "/print").pipe(map((response) => response.data));
    }

    getPlaceInviteCode(place: Partial<Place>) {
        return this.baseService.get("places/" + place.id + "/code");
    }

    getPostInviteCode(post: Partial<Post>) {
        return this.baseService.get("posts/" + post.id + "/code");
    }

    readPlacePost(place: Partial<Place>, post: Partial<Post>): Observable<Transaction> {
        return this.baseService.post("places/" + place.id + "/posts/" + post.id + "/read", null).pipe(map((response) => response?.data));
    }
    likePlacePost(place: Partial<Place>, post: Partial<Post>): Observable<Post> {
        return this.baseService.post("places/" + place.id + "/posts/" + post.id + "/like", null).pipe(map((response) => response?.data));
    }
}
