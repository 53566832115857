import { Injectable, Injector } from "@angular/core";
import { EmitterAction, Receiver } from "@ngxs-labs/emitter";
import { ImmutableContext } from "@ngxs-labs/immer-adapter";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { produce } from "immer";
import { map, mergeMap, tap } from "rxjs/operators";

import {
    Idea,
    IdeaCreateRquest,
    IdeaUpdateRequest,
    Place,
    PlaceLeaveable,
    PlaceReportDeleteRequest,
    PlaceUserRemoveRequest,
    PlaceUsersGetRequest,
    Prize,
    PrizeRequest,
    Reportable,
    UserStream,
    IdeaCommentStoreRequest,
    IdeaVoteRequest,
    PlaceEventGetRequest,
    Event,
    PlaceEventJoinRequest,
    Transaction,
    Election,
    ElectionCreateRequest,
} from "../definitions";
import { BaseService } from "../services/base.service";
import { PlacesService } from "../services/places.service";
import { UserReceivedPoints } from "./user.state";

export class GetPlaces {
    static readonly type = "[Place] Get all places";
}
export class GetPlacesLeaveable {
    static readonly type = "[Place] Get all places with leaveable flags";
}
export class ClearSelectedPlace {
    static readonly type = "[Place] Clear Selected Place";
}
export class GetPlace {
    static readonly type = "[Place] Get Place";
    constructor(public place: Partial<Place>) {}
}
export class DeletePlace {
    static readonly type = "[Place] Delete Place";
    constructor(public place: Partial<Place>) {}
}

export class GetPlaceLicenses {
    static readonly type = "[Place] Get Licenses";
    constructor(public place: Partial<Place>) {}
}
export class GetPlaceStats {
    static readonly type = "[Place] Get Place Stats";
    constructor(public place: Partial<Place>) {}
}
export class GetPlaceAdmins {
    static readonly type = "[Place] Get Place Admins";
    constructor(public place: Partial<Place>) {}
}
export class GetPlaceIdeas {
    static readonly type = "[Place] Get Place Ideas";
    constructor(public place: Partial<Place>, public status: string = "") {}
}
export class GetPlaceIdea {
    static readonly type = "[Place] Get Place Idea";
    constructor(public place: Partial<Place>, public idea: Partial<Idea>) {}
}
export class GetPlaceElection {
    static readonly type = "[Place] Get Place Election";
    constructor(public place: Partial<Place>, public election: Partial<Election>) {}
}
export class ReadPlaceIdea {
    static readonly type = "[Place] Set Place Idea Read";
    constructor(public place: Partial<Place>, public idea: Partial<Idea>) {}
}
export class UnreadPlaceIdea {
    static readonly type = "[Place] Set Place Idea Unread";
    constructor(public place: Partial<Place>, public idea: Partial<Idea>) {}
}
export class FavoritPlaceIdea {
    static readonly type = "[Place] Favorit Place Idea";
    constructor(public place: Partial<Place>, public idea: Partial<Idea>) {}
}
export class UnFavoritPlaceIdea {
    static readonly type = "[Place] UnFavorit Place Idea";
    constructor(public place: Partial<Place>, public idea: Partial<Idea>) {}
}
export class GetPlacePrizes {
    static readonly type = "[Place] Get Place Prices";
    constructor(public place: Partial<Place>) {}
}
export class GetPlacePrize {
    static readonly type = "[Place] Get Place Prize";
    constructor(public place: Partial<Place>, public prize: Partial<Prize>) {}
}
export class ClearPlacePrize {
    static readonly type = "[Place] Clear Place Prize";
    constructor() {}
}
export class ClearPlaceElection {
    static readonly type = "[Place] Clear Place Election";
    constructor() {}
}
export class CreatePlacePrize {
    static readonly type = "[Place] Create Place Prize";
    constructor(public place: Partial<Place>, public prize: PrizeRequest) {}
}

export class UpdatePlacePrize {
    static readonly type = "[Place] Update Place Prize";
    constructor(public place: Partial<Place>, public prize: Partial<Prize>, public params: PrizeRequest) {}
}
export class DeletePlacePrize {
    static readonly type = "[Place] Delete Place Prize";
    constructor(public place: Partial<Place>, public prize: Prize) {}
}
export class DeletePlaceIdea {
    static readonly type = "[Place] Delete Place Idea";
    constructor(public place: Partial<Place>, public idea: Idea) {}
}
export class ClaimPlacePrize {
    static readonly type = "[Place] Claim Place Prize";
    constructor(public code: string) {}
}
export class PrintPlacePrize {
    static readonly type = "[Place] Print Place Prize";
    constructor(public place: Partial<Place>, public prize: Partial<Prize>) {}
}
export class PrintPlaceEvent {
    static readonly type = "[Place] Print Place Event";
    constructor(public place: Partial<Place>, public event: Partial<Event>) {}
}
export class DeletePlaceAdmins {
    static readonly type = "[Place] Delete Place Admins";
    constructor(public place: Partial<Place>, public user: UserStream) {}
}
export class AddPlaceAdmins {
    static readonly type = "[Place] Add Place Admins";
    constructor(public place: Partial<Place>, public username: string) {}
}
export class CreatePlace {
    static readonly type = "[Place] Create a new Place";
    constructor(public title: string) {}
}
export class CreateSponsoredPlace {
    static readonly type = "[Place] Create a new SponsoredPlace";
    constructor(public code: string) {}
}

export class CreateIdea {
    static readonly type = "[Place] Create a new Idea";
    constructor(public place: Partial<Place>, public idea: IdeaCreateRquest) {}
}

export class JoinPlace {
    static readonly type = "[Place] Join a Place";
    constructor(public code: string) {}
}
export class UpdatePlace {
    static readonly type = "[Place] update Place";
    constructor(public place: Partial<Place>, public data: Partial<Place>) {}
}
export class LeavePlace {
    static readonly type = "[Place] Leave a Place";
    constructor(public place: Partial<Place>) {}
}

export class GetPlaceInviteCode {
    static readonly type = "[Place] Get Place Invite Code";
    constructor(public place: Partial<Place>) {}
}

const defaultPlace = {
    id: null,
    ideas: [],
    publicIdeas: [],
    myIdeas: [],
    reports: [],
    posts: [],
    licenses: [],
    prizes: [],
    admins: [],
    highscore: [],
    users: {
        meta: null,
        data: [],
    },
    stats: null,
    code: null,
};
interface PlacesStateModel {
    places: Place[];
    leaveablePlaces: PlaceLeaveable[]; // Für das verlassen gibt es speziellen Place
    prize: Partial<Prize>;
    place: Partial<Place>;
    idea: Partial<Idea>;
    election: Partial<Election>;
    event: Partial<Event>;
}
@State<PlacesStateModel>({
    name: "place",
    defaults: {
        places: [],
        leaveablePlaces: [],
        prize: null,
        idea: null,
        election: null,
        event: null,
        place: defaultPlace,
    },
})
@Injectable()
export class PlacesState {
    private static api: BaseService;

    @Selector()
    static places(state: PlacesStateModel) {
        return state.places;
    }
    @Selector()
    static leaveablePlaces(state: PlacesStateModel) {
        return state.leaveablePlaces;
    }

    @Selector()
    static idea(state: PlacesStateModel) {
        return state.idea;
    }

    @Selector()
    static election(state: PlacesStateModel) {
        return state.election;
    }

    @Selector()
    static event(state: PlacesStateModel) {
        return state.event;
    }

    @Selector()
    static prize(state: PlacesStateModel) {
        return state.prize;
    }

    @Selector()
    static place(state: PlacesStateModel) {
        return state.place;
    }
    @Selector()
    static admins(state: PlacesStateModel) {
        return state.place.admins;
    }
    @Selector()
    static ideas(state: PlacesStateModel) {
        return state.place.ideas;
    }
    @Selector()
    static events(state: PlacesStateModel) {
        return state.place.events;
    }
    @Selector()
    static elections(state: PlacesStateModel) {
        return state.place.elections;
    }
    @Selector()
    static publicIdeas(state: PlacesStateModel) {
        return state.place.publicIdeas;
    }
    @Selector()
    static myIdeas(state: PlacesStateModel) {
        return state.place.myIdeas;
    }

    @Selector()
    static reports(state: PlacesStateModel) {
        return state.place.reports;
    }
    @Selector()
    static licenses(state: PlacesStateModel) {
        return state.place.licenses;
    }
    @Selector()
    static code(state: PlacesStateModel) {
        return state.place.code;
    }

    constructor(private placeService: PlacesService, private store: Store, injector: Injector) {
        PlacesState.api = injector.get<BaseService>(BaseService);
    }

    @Action(ClearSelectedPlace)
    clearSelectedPlace(ctx: StateContext<PlacesStateModel>) {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                draft.place = defaultPlace;
            })
        );
    }

    @Action(GetPlacesLeaveable)
    placesWithLeaveable(ctx: StateContext<PlacesStateModel>) {
        return this.placeService.placesLeaveable().pipe(
            tap((leaveablePlaces) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.leaveablePlaces = leaveablePlaces;
                    })
                );
            })
        );
    }
    @Action(GetPlaces)
    places(ctx: StateContext<PlacesStateModel>) {
        return this.placeService.places().pipe(
            tap((places) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        // PLACEm Place soll immer an der ersten Stelle in der Liste sein
                        // Vom Server kommt eine Liste nach sortiert nach den "neusten" Places
                        draft.places = places.sort((place) => {
                            if (place.id === 1) {
                                return -1; // PLACEm Place nach oben schieben
                            } else {
                                return 0; // Alles andere bleibt so wie es ist
                            }
                        });
                    })
                );
            })
        );
    }

    @Action(DeletePlace)
    deletePlace(ctx: StateContext<PlacesStateModel>, action: DeletePlace) {
        return this.placeService.delete(action.place).pipe(
            tap((place) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.places = draft.places.filter((item) => item.id !== action.place.id);
                    })
                );
            })
        );
    }
    @Action(GetPlace)
    place(ctx: StateContext<PlacesStateModel>, action: GetPlace) {
        return this.placeService.place(action.place).pipe(
            tap((place) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.place = { ...draft.place, ...place };
                    })
                );
            })
        );
    }

    @Action(UpdatePlace)
    update(ctx: StateContext<PlacesStateModel>, action: UpdatePlace) {
        return this.placeService.update(action.place, action.data).pipe(
            tap((place) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.place = { ...draft.place, ...place };
                    })
                );
            })
        );
    }
    @Action(CreatePlace)
    create(ctx: StateContext<PlacesStateModel>, action: CreatePlace) {
        return this.placeService.create(action.title).pipe(
            tap((place) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.places.push(place);
                        draft.place = place;
                    })
                );
            })
        );
    }
    @Action(CreateSponsoredPlace)
    createSponsoredPlace(ctx: StateContext<PlacesStateModel>, action: CreateSponsoredPlace) {
        return this.placeService.createSponsoredPlace(action.code).pipe(
            tap((place) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.places.push(place);
                        draft.place = place;
                    })
                );
            })
        );
    }
    @Action(JoinPlace)
    join(ctx: StateContext<PlacesStateModel>, action: JoinPlace) {
        return this.placeService.join(action.code).pipe(
            tap((place) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.places.push(place);
                        draft.place = place;
                    })
                );
            })
        );
    }
    @Action(LeavePlace)
    leave(ctx: StateContext<PlacesStateModel>, action: LeavePlace) {
        return this.placeService.leave(action.place).pipe(
            tap((response) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.places = draft.places.filter((place) => place.id !== action.place.id);
                    })
                );
            })
        );
    }

    @Action(GetPlaceLicenses)
    licenses(ctx: StateContext<PlacesStateModel>, action: GetPlaceLicenses) {
        return this.placeService.licenses(action.place).pipe(
            tap((licenses) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.place.licenses = licenses;
                    })
                );
            })
        );
    }
    @Action(ClearPlacePrize)
    clearPrize(ctx: StateContext<PlacesStateModel>, action: GetPlacePrize) {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                draft.prize = {};
            })
        );
    }
    @Action(ClearPlaceElection)
    clearElection(ctx: StateContext<PlacesStateModel>, action: GetPlaceElection) {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                draft.election = {};
            })
        );
    }

    @Action(GetPlacePrize)
    prize(ctx: StateContext<PlacesStateModel>, action: GetPlacePrize) {
        return this.placeService.prize(action.place, action.prize).pipe(
            tap((prize) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.prize = prize;
                    })
                );
            })
        );
    }

    @Action(GetPlacePrizes)
    prices(ctx: StateContext<PlacesStateModel>, action: GetPlacePrizes) {
        return this.placeService.prizes(action.place).pipe(
            tap((prizes) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.place.prizes = prizes;
                    })
                );
            })
        );
    }
    @Action(CreatePlacePrize)
    createPrize(ctx: StateContext<PlacesStateModel>, action: CreatePlacePrize) {
        return this.placeService.createPrize(action.place, action.prize).pipe(
            tap((prize) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.prize = prize;
                    })
                );
            }),
            mergeMap(() => ctx.dispatch(new GetPlacePrizes(action.place)))
        );
    }
    @Action(UpdatePlacePrize)
    updatePrize(ctx: StateContext<PlacesStateModel>, action: UpdatePlacePrize) {
        return this.placeService.updatePrize(action.place, action.prize, action.params).pipe(
            tap((prize) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.prize = prize;
                    })
                );
            }),
            mergeMap(() => ctx.dispatch(new GetPlacePrizes(action.place)))
        );
    }
    @Action(DeletePlacePrize)
    deletePrize(ctx: StateContext<PlacesStateModel>, action: DeletePlacePrize) {
        return this.placeService.deletePrize(action.place, action.prize).pipe(
            tap((prizes) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.place.prizes = draft.place.prizes.filter((item) => item.id !== action.prize.id);
                    })
                );
            })
        );
    }
    @Action(ClaimPlacePrize)
    claimPrize(ctx: StateContext<PlacesStateModel>, action: ClaimPlacePrize) {
        return this.placeService.claimPrize(action.code).pipe(
            tap((transaction) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.place.claimed_points += transaction.points;
                    })
                );
            })
        );
    }
    @Action(PrintPlacePrize)
    printPrize(ctx: StateContext<PlacesStateModel>, action: PrintPlacePrize) {
        return this.placeService.printPrize(action.place, action.prize).pipe(
            tap((print) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.prize.qrCode = print.url;
                    })
                );
            })
        );
    }
    @Action(PrintPlaceEvent)
    printEvent(ctx: StateContext<PlacesStateModel>, action: PrintPlaceEvent) {
        return this.placeService.printEvent(action.place, action.event).pipe(
            tap((print) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.event.qrCode = print.url;
                    })
                );
            })
        );
    }

    @Action(GetPlaceAdmins)
    admins(ctx: StateContext<PlacesStateModel>, action: GetPlaceAdmins) {
        return this.placeService.admins(action.place).pipe(
            tap((admins) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.place.admins = admins;
                    })
                );
            })
        );
    }
    @Action(GetPlaceIdeas)
    ideas(ctx: StateContext<PlacesStateModel>, action: GetPlaceIdeas) {
        return this.placeService.ideas(action.place, action.status).pipe(
            tap((ideas) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.place.ideas = ideas;
                    })
                );
            })
        );
    }
    @Action(GetPlaceIdea)
    idea(ctx: StateContext<PlacesStateModel>, action: GetPlaceIdea) {
        return this.placeService.idea(action.place, action.idea).pipe(
            tap((idea) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.idea = idea;
                    })
                );
            })
        );
    }
    @Action(GetPlaceElection)
    election(ctx: StateContext<PlacesStateModel>, action: GetPlaceElection) {
        return this.placeService.election(action.place, action.election).pipe(
            tap((election) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.election = election;
                    })
                );
            })
        );
    }
    @Action(ReadPlaceIdea)
    readIdea(ctx: StateContext<PlacesStateModel>, action: GetPlaceIdea) {
        return this.placeService.readIdea(action.place, action.idea).pipe(
            tap((idea) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        const placeIdea = draft.place.ideas.find((item) => item.id === idea.id);
                        if (placeIdea) {
                            placeIdea.read_at = Math.floor(new Date().getTime() / 1000);
                        }
                        draft.idea = idea;
                    })
                );
            })
        );
    }
    @Action(UnreadPlaceIdea)
    unreadIdea(ctx: StateContext<PlacesStateModel>, action: UnreadPlaceIdea) {
        return this.placeService.unreadIdea(action.place, action.idea).pipe(
            tap((idea) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        const placeIdea = draft.place.ideas.find((item) => item.id === idea.id);
                        if (placeIdea) {
                            placeIdea.read_at = null;
                        }
                        draft.idea = idea;
                    })
                );
            })
        );
    }
    @Action(FavoritPlaceIdea)
    favoriteIdea(ctx: StateContext<PlacesStateModel>, action: FavoritPlaceIdea) {
        return this.placeService.favoritIdea(action.place, action.idea).pipe(
            tap((idea) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        const placeIdea = draft.place.ideas.find((item) => item.id === idea.id);
                        if (placeIdea) {
                            placeIdea.favorite = null;
                        }
                        draft.idea = idea;
                    })
                );
            })
        );
    }
    @Action(UnFavoritPlaceIdea)
    unfavoriteIdea(ctx: StateContext<PlacesStateModel>, action: FavoritPlaceIdea) {
        return this.placeService.unfavoritIdea(action.place, action.idea).pipe(
            tap((idea) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        const placeIdea = draft.place.ideas.find((item) => item.id === idea.id);
                        if (placeIdea) {
                            placeIdea.favorite = null;
                        }
                        draft.idea = idea;
                    })
                );
            })
        );
    }

    @Action(DeletePlaceIdea)
    deleteIdea(ctx: StateContext<PlacesStateModel>, action: DeletePlaceIdea) {
        return this.placeService.deleteIdea(action.place, action.idea).pipe(
            tap((ideas) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.place.ideas = draft.place.ideas.filter((item) => item.id !== action.idea.id);
                    })
                );
            })
        );
    }

    @Action(GetPlaceStats)
    stats(ctx: StateContext<PlacesStateModel>, action: GetPlaceStats) {
        if (!action.place.admin) {
            return;
        }
        return this.placeService.stats(action.place).pipe(
            tap((stats) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.place.stats = stats;
                    })
                );
            })
        );
    }

    @Action(DeletePlaceAdmins)
    removeAdmin(ctx: StateContext<PlacesStateModel>, action: DeletePlaceAdmins) {
        return this.placeService.removeAdmin(action.place, action.user).pipe(
            tap((admins) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.place.admins = draft.place.admins.filter((admin) => admin.id !== action.user.id);
                    })
                );
            })
        );
    }
    @Action(AddPlaceAdmins)
    addAdmin(ctx: StateContext<PlacesStateModel>, action: AddPlaceAdmins) {
        return this.placeService.addAdmin(action.place, action.username).pipe(
            tap((admin) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.place.admins.push(admin);
                    })
                );
            })
        );
    }

    @Action(CreateIdea)
    createIdea(ctx: StateContext<PlacesStateModel>, action: CreateIdea) {
        return this.placeService.createIdea(action.place, action.idea).pipe(
            tap((post) => {
                ctx.setState(produce(ctx.getState(), (draft) => {}));
            })
        );
    }
    @Action(GetPlaceInviteCode)
    getPlaceInviteCode(ctx: StateContext<PlacesStateModel>, action: CreateIdea) {
        return this.placeService.getPlaceInviteCode(action.place).pipe(
            tap((params) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.place.code = params.code;
                        draft.place.qrcodeURL = params.qrcodeURL;
                    })
                );
            })
        );
    }
    @Receiver()
    @ImmutableContext()
    public static report({ setState }: StateContext<PlacesStateModel>, { payload }: EmitterAction<Reportable>) {
        return PlacesState.api.post("reports", payload).pipe(
            tap((result) => {
                setState((state: PlacesStateModel) => {
                    return state;
                });
            })
        );
    }
    @Receiver()
    @ImmutableContext()
    public static removeReport({ setState }: StateContext<PlacesStateModel>, { payload }: EmitterAction<PlaceReportDeleteRequest>) {
        return PlacesState.api.delete(`places/${payload.place_id}/reports/${payload.report_id}`).pipe(
            map((response) => response.data),
            tap((result) => {
                setState((state: PlacesStateModel) => {
                    state.place.reports = result;
                    return state;
                });
            })
        );
    }

    @Receiver()
    @ImmutableContext()
    public static getReports({ setState }: StateContext<PlacesStateModel>, { payload }: EmitterAction<Partial<Place>>) {
        return PlacesState.api.get(`places/${payload.id}/reports`).pipe(
            map((response) => response.data),
            tap((result) => {
                setState((state: PlacesStateModel) => {
                    state.place.reports = result;
                    return state;
                });
            })
        );
    }

    @Receiver()
    @ImmutableContext()
    public static getPlaceMembers({ setState }: StateContext<PlacesStateModel>, { payload }: EmitterAction<PlaceUsersGetRequest>) {
        if (payload.page === 1) {
            setState((state: PlacesStateModel) => {
                state.place.users.data = [];
                state.place.users.meta = null;
                return state;
            });
        }

        return PlacesState.api.get(`places/${payload.place_id}/users?page=${payload.page}`).pipe(
            tap((result) => {
                if (payload.page === 1) {
                    setState((state: PlacesStateModel) => {
                        state.place.users.data = result.data;
                        state.place.users.meta = result.meta;
                        return state;
                    });
                } else {
                    setState((state: PlacesStateModel) => {
                        state.place.users.data = state.place.users.data.concat(result.data);
                        state.place.users.meta = result.meta;
                        return state;
                    });
                }
            })
        );
    }
    @Receiver()
    @ImmutableContext()
    public static removePlaceMember({ setState }: StateContext<PlacesStateModel>, { payload }: EmitterAction<PlaceUserRemoveRequest>) {
        return PlacesState.api.delete(`places/${payload.place_id}/users/${payload.user_id}`).pipe(
            tap((result) => {
                setState((state: PlacesStateModel) => {
                    state.place.users.data = state.place.users.data.filter((item) => item.id !== payload.user_id);
                    return state;
                });
            })
        );
    }

    @Receiver()
    @ImmutableContext()
    public static getPublicIdeas({ setState }: StateContext<PlacesStateModel>, { payload }: EmitterAction<Partial<Place>>) {
        return PlacesState.api.get(`places/${payload.id}/ideas?status=public`).pipe(
            map((response) => response.data),
            tap((result) => {
                setState((state: PlacesStateModel) => {
                    state.place.publicIdeas = result;
                    return state;
                });
            })
        );
    }
    @Receiver()
    @ImmutableContext()
    public static getAllIdeas({ setState }: StateContext<PlacesStateModel>, { payload }: EmitterAction<Partial<Place>>) {
        return PlacesState.api.get(`places/${payload.id}/ideas?status=inbox`).pipe(
            map((response) => response.data),
            tap((result) => {
                setState((state: PlacesStateModel) => {
                    state.place.ideas = result;
                    return state;
                });
            })
        );
    }
    @Receiver()
    @ImmutableContext()
    public static getMyIdeas({ setState }: StateContext<PlacesStateModel>, { payload }: EmitterAction<Partial<Place>>) {
        return PlacesState.api.get(`places/${payload.id}/ideas?status=my`).pipe(
            map((response) => response.data),
            tap((result) => {
                setState((state: PlacesStateModel) => {
                    state.place.myIdeas = result;
                    return state;
                });
            })
        );
    }
    @Receiver()
    @ImmutableContext()
    public static updateIdea({ setState }: StateContext<PlacesStateModel>, { payload }: EmitterAction<IdeaUpdateRequest>) {
        return PlacesState.api.put(`places/${payload.place_id}/ideas/${payload.idea_id}`, { status: payload.status }).pipe(
            map((response) => response.data),
            tap((result) => {
                setState((state: PlacesStateModel) => {
                    state.idea = result;
                    return state;
                });
            })
        );
    }
    @Receiver()
    @ImmutableContext()
    public static storeIdeaComment({ setState }: StateContext<PlacesStateModel>, { payload }: EmitterAction<IdeaCommentStoreRequest>) {
        return PlacesState.api.post(`ideas/${payload.idea_id}/comments`, { comment: payload.comment }).pipe(
            map((response) => response.data),
            tap((result) => {
                setState((state: PlacesStateModel) => {
                    state.idea.comments = result;
                    return state;
                });
            })
        );
    }
    @Receiver()
    @ImmutableContext()
    public static voteIdea({ setState }: StateContext<PlacesStateModel>, { payload }: EmitterAction<IdeaVoteRequest>) {
        return PlacesState.api.get(`places/${payload.place_id}/ideas/${payload.idea_id}/${payload.vote}Vote`).pipe(
            map((response) => response.data),
            tap((result) => {
                setState((state: PlacesStateModel) => {
                    state.idea = result;
                    const indexOf = state.place.publicIdeas.findIndex((i) => i.id == payload.idea_id);
                    if (indexOf !== -1) {
                        state.place.publicIdeas[indexOf] = result;
                    }
                    return state;
                });
            })
        );
    }
    @Receiver()
    @ImmutableContext()
    public static getEvents({ setState }: StateContext<PlacesStateModel>, { payload }: EmitterAction<Partial<Place>>) {
        return PlacesState.api.get(`places/${payload.id}/events`).pipe(
            map((response) => response.data),
            tap((result) => {
                setState((state: PlacesStateModel) => {
                    state.place.events = result;
                    return state;
                });
            })
        );
    }
    @Receiver()
    @ImmutableContext()
    public static getElections({ setState }: StateContext<PlacesStateModel>, { payload }: EmitterAction<Partial<Place>>) {
        return PlacesState.api.get(`places/${payload.id}/elections`).pipe(
            map((response) => response.data),
            tap((result) => {
                setState((state: PlacesStateModel) => {
                    state.place.elections = result;
                    return state;
                });
            })
        );
    }
    @Receiver()
    @ImmutableContext()
    public static createElection({ setState }: StateContext<PlacesStateModel>, { payload }: EmitterAction<ElectionCreateRequest>) {

        return PlacesState.api.post(`places/${payload.place_id}/elections`, { name: payload.name }).pipe(
            map((response) => response.data),
            tap((result) => {
                setState((state: PlacesStateModel) => {
                    state.election = result;
                    return state;
                });
            })
        );
    }

    @Receiver()
    @ImmutableContext()
    public static getEvent({ setState }: StateContext<PlacesStateModel>, { payload }: EmitterAction<PlaceEventGetRequest>) {
        return PlacesState.api.get(`places/${payload.place_id}/events/${payload.event_id}`).pipe(
            map((response) => response.data),
            tap((result) => {
                setState((state: PlacesStateModel) => {
                    state.event = result;
                    return state;
                });
            })
        );
    }
    @Receiver()
    @ImmutableContext()
    public static clearEvent({ setState }: StateContext<PlacesStateModel>, { payload }: EmitterAction) {
        setState((state: PlacesStateModel) => {
            state.event = null;
            return state;
        });
    }
    @Receiver()
    @ImmutableContext()
    public static createEvent({ setState }: StateContext<PlacesStateModel>, { payload }: EmitterAction<Event>) {
        return PlacesState.api.post(`places/${payload.place_id}/events/`, payload).pipe(
            map((response) => response.data),
            tap((result) => {
                setState((state: PlacesStateModel) => {
                    state.event = result;
                    return state;
                });
            })
        );
    }
    @Receiver()
    @ImmutableContext()
    public static updateEvent({ setState }: StateContext<PlacesStateModel>, { payload }: EmitterAction<Event>) {
        return PlacesState.api.put(`places/${payload.place_id}/events/${payload.id}`, payload).pipe(
            map((response) => response.data),
            tap((result) => {
                setState((state: PlacesStateModel) => {
                    state.event = result;
                    return state;
                });
            })
        );
    }
    @Receiver()
    @ImmutableContext()
    public static joinEvent(
        { setState, getState, dispatch }: StateContext<PlacesStateModel>,
        { payload }: EmitterAction<PlaceEventJoinRequest>
    ) {
        return PlacesState.api.post(`places/${payload.place_id}/events/${payload.event_id}/join`, payload).pipe(
            tap((transaction: Transaction) => {
                dispatch(new UserReceivedPoints(transaction));

                setState((state: PlacesStateModel) => {
                    state.event.joined = true;
                    return state;
                });
            })
        );
    }
    @Receiver()
    @ImmutableContext()
    public static deleteEvent({ setState }: StateContext<PlacesStateModel>, { payload }: EmitterAction<Event>) {
        return PlacesState.api.delete(`places/${payload.place_id}/events/${payload.id}`).pipe(
            tap((result) => {
                setState((state: PlacesStateModel) => {
                    state.event = null;
                    return state;
                });
            })
        );
    }
}
